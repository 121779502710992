<template>
  <v-container :style="backgroundStyle" fill-height fluid ma-0 pa-0>
    <v-row align="center" justify="center">
      <v-col>
        <v-card
          class="mx-auto py-8 px-4"
          max-width="400"
          outlined
        >
          <p class="text-h4 mb-2 primary--text text-center">Welcome</p>
          <p class="subheading grey--text text--darken-2 text-center">Sign-in with your InvisMI iCare account.</p>

          <v-sheet
            class="mx-auto text-center"
            max-width="300"
          >
            <v-form
              @submit.prevent="SubmitForm"
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="email"
                :prepend-inner-icon="EMAIL_ICON"
                :rules="[rules.required]"
                @keydown.enter="submitForm()"
                label="Email"
                outlined
                validate-on-blur
              />

              <v-text-field
                v-model="password"
                :append-icon="appendIcon"
                :prepend-inner-icon="LOCK_ICON"
                :rules="[rules.required]"
                :type="fieldType"
                @click:append="showPasswordToggle()"
                @keydown.enter="submitForm()"
                label="Password"
                outlined
                validate-on-blur
              />
            </v-form>

            <v-divider class="my-4" />

            <v-progress-linear
              v-show="signingIn"
              class="mt-0 mb-4"
              color="primary"
              height="36"
              indeterminate
              rounded
            />

            <v-btn
              v-show="!signingIn"
              @click="submitForm()"
              block
              outlined
              color="primary"
              v-text="'Sign-in'"
            />

            <v-divider class="my-4" />

            <router-link
              :to="FORGOT_PASSWORD_URL"
              class="subtitle-1 text-decoration-none"
            >
              Forget your password?
            </router-link>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, } from 'vuex'

import { ICONS, ROUTES, } from '../constants';
import { push } from '../router'
import { transformError } from '../services/utility.service'
import AuthService from '../services/api/auth.service'

export default {
  data: () => ({
    email: '',
    password: '',
    rules: {
      required: value => !!value || 'Required.',
    },
    signingIn: false,
    valid: false,
    visiblePassword: false,
  }),
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
    }),
    appendIcon() {
      return this.visiblePassword ? ICONS.HIDE : ICONS.SHOW
    },
    fieldType() {
      return this.visiblePassword ? 'text' : 'password'
    }
  },
  methods: {
    ...mapActions({
      errorMessage: 'message/errorMessage',
      setBackground: 'sitemap/setBackground',
    }),
    initTemplateConstants() {
      this.EMAIL_ICON = ICONS.EMAIL
      this.LOCK_ICON = ICONS.LOCK

      this.FORGOT_PASSWORD_URL = ROUTES.FORGOT_PASSWORD
    },
    showPasswordToggle() {
      this.visiblePassword = !this.visiblePassword
    },
    submitForm(event) {
      if (!this.$refs.form.validate()) {
        return
      }

      this.signingIn = true

      AuthService.login({
        email: this.email,
        password: this.password
      })
        .then(() => {
          push(ROUTES.DASHBOARD)
        })
        .catch(error => {
          this.errorMessage(`Sign-in attempt failed. ${transformError(error)}`)
        })
        .finally(() => {
          this.signingIn = false
        })
    },
  },
  created() {
    this.setBackground()
    this.initTemplateConstants()
  },
}
</script>
